import React from 'react'
import Layout from '../layouts/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Flex } from 'theme-ui'
import { Icon } from '../components/Icon'
import Section from '../components/Section'
import { Link } from 'gatsby'
import BasicHero from '../components/Hero/BasicHero'
import BackgroundImageHero from '../components/Hero/BackgroundImageHero'

const info = [
  { year: 1997, text: 'Company founded with one store in California' },
  {
    year: 2011,
    text: 'Entered Canadian market with the acquisition of <b>Cash Money</b>',
  },
  {
    year: 2017,
    text: 'Created new near-prime Canadian brand, <b>LendDirect</b>',
  },
  {
    year: 2021,
    text: 'Expanded into near-prime lending in the US with acquisition of <b>Heights Finance</b>',
  },
  {
    year: 2022,
    text: 'Sold US legacy lending business and acquired <b>First Heritage Credit</b>',
  },
  {
    year: 2024,
    text: 'Restructured to better position company for long-term profitable growth',
  },
  {
    year: 2025,
    text: 'Changed corporate name to <b>Attain Finance</b>',
  },
]

const iconList = [
  {
    header: 'Access to credit for consumers',
    text: 'Our tech-enabled platform will continue to focus on accessibility, reliability, and ease-of-use',
    icon: 'credit-cards',
  },
  {
    header: 'Multiple credit options for customers’ changing needs',
    text: 'From in-store to online, we adapt with our customers’ preferences',
    icon: 'mobile-payments',
  },
  {
    header: 'Credit risk mitigation and underwriting',
    text: 'As we continue diversifying our business, our proprietary data and technologies get smarter and more efficient',
    icon: 'analytics',
  },
  {
    header: 'Playing our role in a changing landscape',
    text: 'We’re committed to responsible lending and giving back to our communities',
    icon: 'community',
  },
]
const CreditSolutions = () => {
  return (
    <Layout theme="light">
      <BackgroundImageHero
        theme="light"
        heading="Helping customers achieve their financial goals"
        images={{
          mobile: '../images/hero/About_Mobile.jpg',
          desktop: '../images/hero/About_Us.jpg',
        }}
      />
      {/* <BasicHero
        restrictText
        heading="Helping customers achieve their financial goals"
        image={
          <StaticImage
            src="../images/hero/about-banner-img.png"
            alt="happy woman"
            placeholder="none"
            sx={{
              top: -30,
            }}
          />
        }
      /> */}

      <Section
        bgColor="white"
        preHeader="Where We Started"
        header="From a single store to hundreds of locations
throughout Canada and the U.S."
      >
        {' '}
        <Flex
          sx={{
            alignItems: ['flex-start', null, null, 'center'],
            flexDirection: ['column', null, null, 'row'],
            flexWrap: 'wrap',
            px: [0, null, null, 0],
            pt: [0, null, null, 5],
          }}
        >
          {info.map((item, index) => (
            <Flex
              key={index}
              sx={{
                flexDirection: 'column',
                width: '100%',
                maxWidth: ['100%', null, null, 273],
                p: [0, null, null, 3],
                py: [3, null, null, 0],
              }}
            >
              <h2
                sx={{
                  textAlign: 'left',
                  fontSize: 32,
                  color: index == info.length - 1 ? 'accent' : 'black',
                }}
              >
                {item.year}
              </h2>
              <p
                sx={{
                  textAlign: 'left',
                  minHeight: [0, null, null, '80px'],
                  b: {
                    color: 'accent',
                  },
                }}
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
            </Flex>
          ))}
        </Flex>
      </Section>
      <Section
        theme="dark"
        preHeader="Where We’re Going"
        header="Remaining customer-centric while expanding our core products and technology, propelling us to new heights"
        subHeader="The mission we launched over 25 years ago is the same mission we uphold today and will continue delivering on tomorrow.."
      >
        <Flex
          sx={{
            gap: 3,
            flexWrap: 'wrap',
            py: [0, null, null, 4],
          }}
        >
          {iconList.map((item, index) => (
            <Flex
              key={index}
              sx={{
                flexDirection: 'column',

                maxWidth: 262,
                width: '100%',
              }}
            >
              <Icon
                name={item.icon}
                size={60}
                sx={{
                  my: 3,
                }}
              />
              <Flex sx={{ flexDirection: 'column' }}>
                <h2 sx={{ fontSize: 18, lineHeight: 1.5, textAlign: 'left' }}>
                  {item.header}
                </h2>
                <p
                  sx={{
                    fontSize: 15,
                    lineHeight: 1.5,
                    fontWeight: 300,
                    textAlign: 'left',
                  }}
                >
                  {item.text}
                </p>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Section>
      <Section
        image={
          <StaticImage
            src="../images/sections/about-text-img.png"
            alt="woman"
            layout="fixed"
            placeholder="none"
          />
        }
        bgColor="white"
        preHeader="Our People"
        header="An evolving business spearheaded by a team of industry leaders"
        subHeader="With over 500 branches and 2,400 employees in the U.S. and Canada, our leadership stands by three pillars to strategically position the company for the future: enhancing our foundation, growing responsibly, and executing with excellence.​"
      >
        <Link
          to="/leadership"
          sx={{
            variant: 'buttons.primary',
            width: 140,
            mt: [4, null, null, 50],
          }}
        >
          Our People
        </Link>
      </Section>
    </Layout>
  )
}

export default CreditSolutions
