import React from 'react'
import { Container, Flex } from 'theme-ui'

interface Props {
  header: string
  preHeader?: string
  subHeader?: string
  children?: any
  image?: any
  bgColor?: string
  theme?: 'dark' | 'light'
}

const Section = ({
  header,
  preHeader,
  image,
  subHeader,
  children,
  bgColor = 'brand.veniceBlue',
  theme = 'light',
}: Props) => {
  return (
    <section sx={{ bg: bgColor, overflow: 'hidden' }}>
      <Container
        sx={{
          flexDirection: ['column', null, null, image ? 'row' : 'column'],
          color: theme === 'light' ? 'black' : 'white',
          p: 5,
          px: [4, null, null, 3],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            width: ['100%', null, null, image ? '50%' : '100%'],
          }}
        >
          {preHeader && (
            <p
              sx={{
                color: 'accent',
                fontSize: 20,
                textAlign: 'left',
              }}
              dangerouslySetInnerHTML={{ __html: preHeader }}
            />
          )}

          <h2
            sx={{
              fontSize: [32, null, null, 38],
              lineHeight: 1.5,
              textAlign: 'left',
              maxWidth: 900,
            }}
            dangerouslySetInnerHTML={{ __html: header }}
          />

          {subHeader && (
            <p
              sx={{
                fontSize: [18],
                lineHeight: 1.5,
                fontWeight: 300,
                maxWidth: 750,
                textAlign: 'left',
              }}
              dangerouslySetInnerHTML={{ __html: subHeader }}
            />
          )}
          {children}
        </Flex>

        {image && image}
      </Container>
    </section>
  )
}

export default Section
